import React from "react";

import ContactForm from '../../lib/components/ContactForm';
import Layout from "../../lib/components/Layout";
import PageTitlePanel from '../../lib/components/PageTitlePanel';
import TextTitle from "../../lib/components/TextTitle";
import Storage from '../../lib/storage';

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.serviceShowerRepair.metadata.title}
      description={Storage.pages.serviceShowerRepair.metadata.description}
      // metadataScripts={Storage.pages.serviceShowerRepair.metadata.scripts}
      subPages={[Storage.pages.services.metadata.title]}
      pageUrl={location.pathname}
      fixedButton="backToServices"
      bgNavSelector="titlePanel"
    >
      <PageTitlePanel
        title={Storage.pages.serviceShowerRepair.titlePanel.title}
        subtitle={Storage.pages.serviceShowerRepair.metadata.description}
        backgroundImg={Storage.pages.serviceShowerRepair.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      <div className="relative w-full nexus-bg h-page-p v-page-p">
        <div className="flex flex-col items-center text-center gap-8">
          <div className="flex flex-col items-center text-center gap-4">
            <TextTitle
              title='The Importance of Shower Maintenance'
              titleIcon={
                <div className="flex items-center justify-center w-10 h-10 bg-green-600 rounded-full p-2">
                  <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path className="nexus-svg" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z" />
                  </svg>
                </div>
              }
            />
            <p className="text-base nexus-text select-none">Just as a car requires regular servicing to stay in a good working condition, showers, baths, and other wet areas in a home also require regular maintenance to ensure they continue to function properly over time without any structural ramifications.</p>
            <p className="text-base nexus-text select-none">In most cases, it is possible to repair a shower without removing tiles. If necessary, we can waterproof to seal the area to prevent it from future leaks and damages.</p>
            <p className="nexus-text font-bold select-none">Nexus Commercial Services provides repairing and waterproofing services for your bathroom, ensuring foundations are protected from any water damages.</p>
            <p className="nexus-text select-none">Contact our team to arrange your next Shower Repair Service, which may include:</p>
          </div>
          <ul className="nexus-text text-sm text-left list-disc grid list-inside gap-2 select-none">
            <li>Tile Cleaning</li>
            <li>Grout Cleaning</li>
            <li>Re-siliconing</li>
            <li>Grout Sealing</li>
            <li>Tile Sealing</li>
            <li>Re-Tiling (if necessary)</li>
            <li>Re-Grouting (if necessary)</li>
          </ul>

          <div className="grid gap-6 text-center">
            <p className="nexus-text uppercase select-none">Ask about our qualifications to see how we can provide you a peace of mind around {Storage.pages.serviceShowerRepair.metadata.title} in Melbourne.</p>
            <div className="flex justify-center">
              <div
                role='button'
                tabIndex={0}
                className="group block w-10 h-10 md:animate-bounce cursor-pointer"
                // variants={fadeDown}
                initial="initial"
                // animate={control}
                onClick={() => { if (typeof document !== 'undefined') document.getElementById('showerRepairCta').scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
                onKeyUp={() => null}
              onKeyDown={(e) => { e.preventDefault(); if (e.key === 'Enter') if (typeof document !== 'undefined') document.getElementById('showerRepairCta').scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path className="nexus-svg-inverted opacity-80 group-hover:opacity-100" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Accordion
        faqs={Storage.pages.serviceShowerRepair.accordion}
      /> */}

      <div id='showerRepairCta' className="relative w-full h-page-p v-page-p nexus-bg">
        <div className="flex flex-col gap-4">
          <div className="grid gap-4">
            <h1 className="font-bold uppercase nexus-text">Enquiries Form</h1>
            <p className="font-normal nexus-text">Submit your enquiry below and we will reach out to you directly.</p>
          </div>
          <ContactForm />
        </div>
      </div>

    </Layout>
  )
}

export default Page
